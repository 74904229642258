import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import Events from '@components/Events'

const EventsPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
    hero,
  } = data.contentfulEvents
  const events = data.allContentfulEvent.nodes

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <Hero data={hero} />

      <Events data={events} />

      <Footer />
    </>
  )
}

export default EventsPage

export const pageQuery = graphql`
  query EventsPage($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      hero {
        ...ContentfulHero
      }
    }
    allContentfulEvent(sort: { fields: startTime, order: DESC }) {
      nodes {
        ...ContentfulEvent
      }
    }
  }
`
