import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EventsPast, EventsUpcoming } from './index.style'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading2, TextBody } from '@components/TextStyles'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import Border from '@components/Border'
import { colors } from '@styles/vars/colors.style'
import Event from '@components/Event'
import { getFilteredEvents } from '@utils/utils'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Events = ({ data }) => {
  const [upcomingEvents, setUpcomingEvents] = useState()
  const [pastEvents, setPastEvents] = useState()

  useLayoutEffect(() => {
    const filteredEvents = getFilteredEvents(data)

    setUpcomingEvents(filteredEvents.upcoming)
    setPastEvents(filteredEvents.past)
  }, [data])

  return (
    <>
      <EventsUpcoming>
        <Container>
          <Spacer size={[51, 102]} />

          <AnimateSlideIn>
            <Heading2 secondary uppercase>
              Upcoming Events
            </Heading2>
          </AnimateSlideIn>

          <Spacer size={[24, 48]} />

          {upcomingEvents ? (
            React.Children.toArray(
              upcomingEvents.map((event, eventIndex) => (
                <>
                  {eventIndex > 0 && <Spacer size={[16, 32]} />}

                  <Event data={event} />
                </>
              ))
            )
          ) : (
            <AnimateSlideIn>
              <TextBody>We have no upcoming events currently</TextBody>
            </AnimateSlideIn>
          )}

          <Spacer size={[44, 88]} />
        </Container>
      </EventsUpcoming>

      {pastEvents && (
        <EventsPast>
          <Border color={colors.cream} />

          <Container>
            <Spacer size={[51, 102]} />

            <AnimateSlideIn>
              <Heading2 secondary uppercase>
                Past Events
              </Heading2>
            </AnimateSlideIn>

            <Spacer size={[24, 48]} />

            {React.Children.toArray(
              pastEvents.map((event, eventIndex) => (
                <>
                  {eventIndex > 0 && <Spacer size={[16, 32]} />}

                  <Event data={event} backgroundColor={colors.light} />
                </>
              ))
            )}

            <Spacer size={[44, 88]} />
          </Container>
        </EventsPast>
      )}
    </>
  )
}

Events.propTypes = {
  upcoming: PropTypes.array,
  past: PropTypes.array,
}

export default Events
