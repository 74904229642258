import { colors } from '@styles/vars/colors.style'
import styled from 'styled-components'

export const EventsUpcoming = styled.div`
  text-align: center;
  background-color: ${colors.light};
`

export const EventsPast = styled.div`
  position: relative;
  text-align: center;
  background-color: ${colors.cream};
`
